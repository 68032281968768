import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private _router: Router,
		private _cookieService: CookieService,
	) {
	}

	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// Не прерываем запрос авторизации
		if (
			req.url.indexOf('/auth/login') >= 0 ||
			req.url.indexOf('/assets/icons') >= 0
		) {
			return next.handle(req);
		}

		// Ко всем остальным запросам добавляем заголовок авторизации
		const token = this._cookieService.get('accessToken');

		if (token) {
			const interceptedReq = req.clone({
				setHeaders: {'Authorization': 'Bearer ' + token},
			});

			return next.handle(interceptedReq);
		} else if (
			req.url.indexOf('/auth/get-front-description') >= 0 ||
			req.url.indexOf('/dashboard') >= 0
		) {
			return next.handle(req);
		} else {
			this._router.navigate(['/']);
		}
	}
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Observable, Subscriber} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AuthService} from '../../modules/auth/services/auth.service';

interface ICallerRequest {
	subscriber: Subscriber<any>;
	failedRequest: HttpRequest<any>;
}

@UntilDestroy()
@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
	private _refreshInProgress: boolean;
	private _requests: ICallerRequest[];

	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _location: Location,
		private _httpClient: HttpClient,
		private _loaderService: NgxUiLoaderService,
	) {
		this._requests = [];
	}

	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (req.url.indexOf('/auth/') >= 0) {
			return next.handle(req);
		}

		const observable = new Observable<HttpEvent<any>>((subscriber) => {
			const originalRequestSubscription = next.handle(req)
				.subscribe((response) => {
						subscriber.next(response);
					},
					(error) => {
						this._loaderService.stopAll();

						if (error.status === 401) {
							this.handleUnauthorizedError(subscriber, req);
						} else if (error.status === 403) {
							this._router.navigate(['/errors/forbidden']);
						} else if (error.status === 404) {
							this._router.navigate(['/errors/not-found']);
						} else if (error.status === 500) {
							this._router.navigate(['/errors/server-error']);
						} else if (error.status === 504) {
							this._location.back();
						} else {
							subscriber.error(error);
						}
					},
					() => {
						subscriber.complete();
					});

			return () => {
				originalRequestSubscription.unsubscribe();
			};
		});

		return observable;
	}

	private handleUnauthorizedError(
		subscriber: Subscriber<any>,
		request: HttpRequest<any>
	): void {
		this._requests.push({subscriber, failedRequest: request});

		if (!this._refreshInProgress) {
			this._refreshInProgress = true;
			this._authService.refreshToken()
				.subscribe((authHeader) => {
						this._authService.updateTokens(authHeader['accessToken'], authHeader['refreshToken']);
						const access = 'Bearer ' + this._authService.getTokens().access;
						// const access = ''
						this.repeatFailedRequests(access);
					},
					() => {
						this._authService.logout();
					}, () => {
						this._refreshInProgress = false;
					});
		}
	}

	private repeatFailedRequests(authHeader): void {
		this._requests.forEach((c) => {
			const requestWithNewToken = c.failedRequest.clone({
				headers: c.failedRequest.headers.set('Authorization', authHeader)
			});

			this.repeatRequest(requestWithNewToken, c.subscriber);
		});

		this._requests = [];
	}

	private repeatRequest(
		requestWithNewToken: HttpRequest<any>,
		subscriber: Subscriber<any>
	): void {
		this._httpClient.request(requestWithNewToken)
			.pipe(untilDestroyed(this))
			.subscribe((res) => {
					subscriber.next(res);
				},
				(err) => {
					if (err.status === 401) {
						this._authService.logout();
					}
					subscriber.error(err);
				},
				() => {
					subscriber.complete();
				});
	}
}

/* "Barrel" of Http Interceptors */
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AuthInterceptor} from './auth-interceptor';
import {HttpErrorHandlerInterceptor} from './http-error-handler-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	// Интерцептор авторизации
	{
		provide: HTTP_INTERCEPTORS,
		useClass:
		AuthInterceptor,
		multi: true
	},
	// перехват ошибок
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorHandlerInterceptor,
		multi: true
	},
];
